import React, { useState, useEffect, useRef } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import axios from 'axios';
import { FaRedoAlt, FaCopy } from 'react-icons/fa';
import './App.css';
import { Modal, Button, Input, Form } from 'antd';
import Spinner from './Spinner.gif';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserTie } from '@fortawesome/free-solid-svg-icons';

function App() {
  const [productName, setProductName] = useState('');
  const [category, setCategory] = useState('');
  const [listingDetails, setListingDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [formData, setFormData] = useState({ name: '', phone: '', email: '' });
  const selectRef = useRef(null);

  const trackClickCount = () => {
    let count = parseInt(localStorage.getItem('clickCount')) || 0;
    count += 1;
    localStorage.setItem('clickCount', count);

    if (count === 2) { // Show modal only after 2 clicks
      setModalVisible(true);
    }
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  const handleFormChange = (changedValues) => {
    setFormData({ ...formData, ...changedValues });
    validateForm({ ...formData, ...changedValues });
  };

  const validateForm = (data) => {
    const { name, phone, email } = data;
    const nameValid = /^[A-Za-z\s]+$/.test(name); // Validate name
    const phoneValid = /^\d{7,}$/.test(phone); // Validate phone number (minimum 7 digits)
    const emailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email); // Validate email

    setFormValid(nameValid && phoneValid && emailValid);
  };

  const handleModalSubmit = async () => {
    if (formValid) {
      console.log('Form Submitted:', formData);
      
      try {
        await axios.post('https://www.acumen.backend.dymax.a2hosted.com/send-user-details', formData);
        toast.success('Enjoy your free unlimted access!');
      } catch (error) {
        toast.error('Failed to send your details.');
      }
      
      setModalVisible(false); // Close modal after successful form submission
      localStorage.setItem('formSubmitted', 'true'); // Store submission status
    } else {
      toast.error('Please fill in the form correctly.');
    }
  };
  
  const categories = [
    'Amazon Music', 'Kindle E-readers & Books', 'Amazon Appstore', 'Electronics',
    'Computers', 'Smart Home', 'Arts & Crafts', 'Automotive', 'Baby', 'Beauty and Personal Care',
    'Women\'s Fashion', 'Men\'s Fashion', 'Girls\' Fashion', 'Boys\' Fashion',
    'Health and Household', 'Home and Kitchen', 'Industrial and Scientific',
    'Luggage', 'Movies & Television', 'Pet Supplies', 'Software', 'Sports and Outdoors',
    'Tools & Home Improvement', 'Toys and Games', 'Video Games'
  ];

  const handleSubmit = async (event) => {
    event.preventDefault();
    trackClickCount(); // Track click count on form submission

    setLoading(true);
    try {
      const response = await axios.post('https://www.acumen.backend.dymax.a2hosted.com/generate-listing', {
        productName,
        category,
      });
      setListingDetails(response.data);
    } catch (error) {
      console.error('Error generating listing details', error);
    } finally {
      setLoading(false);
    }
  };


  const handleCopy = () => {
    toast.success('Copied to clipboard!');
  };

 
    const handleClick = () => {
      window.open('https://dymazon.com/get-a-quote/', '_blank');
    };


  useEffect(() => {
    const formSubmitted = localStorage.getItem('formSubmitted');
    if (formSubmitted) {
      setModalVisible(false); // Ensure modal does not show if form was already submitted
    }
  }, []);

  return (
    <div className="App">
      <ToastContainer />
      <header className="App-header">
        <img src={'https://dymazon.com/wp-content/uploads/2022/06/Final-logo-01-e1669371222503-2048x640.png'} className="logo"/>
        <h1>Generate your listing now</h1>
        {!listingDetails && (
          <form onSubmit={handleSubmit} className="input-form">
            <input
              type="text"
              placeholder="Enter Product Name"
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
              required
            />
            <input
              type="text"
              placeholder="Enter Competitor's Listing URL"
            />
            <select
              ref={selectRef}
              value={category}
              onChange={(e) => {
                setCategory(e.target.value);
                selectRef.current.blur();
                setIsExpanded(false);
              }}
              required
              onFocus={() => setIsExpanded(true)}
              onBlur={() => setIsExpanded(false)}
              size={isExpanded ? 10 : 1}
            >
              <option value="" disabled>Select Category</option>
              {categories.map((categoryItem, index) => (
                <option key={index} value={categoryItem}>
                  {categoryItem}
                </option>
              ))}
            </select>
            <button type="submit" className="btn-primary">
              Generate Listing
            </button>
          </form>
        )}

        {loading && <img src={Spinner} alt="Loading..." className="spinner" />}

        {listingDetails && (
          <>
            <div className="listing-preview">
              <div className="listing-title">
                <h2>Title <CopyToClipboard text={listingDetails.title} onCopy={handleCopy}>
                  <FaCopy className="copy-icon" />
                </CopyToClipboard></h2>
                <span>{listingDetails.title}</span>
              </div>

              <div className="listing-description">
                <h2>Description <CopyToClipboard text={listingDetails.description} onCopy={handleCopy}>
                  <FaCopy className="copy-icon" />
                </CopyToClipboard></h2>
                <div>
                  {listingDetails.description.split('\n').map((line, index) => (
                    <p key={index}>{line}</p>
                  ))}
                </div>
              </div>

              <div className="listing-aboutme">
                <h2>About the Item <CopyToClipboard text={listingDetails.about} onCopy={handleCopy}>
                  <FaCopy className="copy-icon" />
                </CopyToClipboard></h2>
                <ul>
                  {listingDetails.about?.map((point) => (
                    <p key={point}>{point}</p>
                  ))}
                </ul>
              </div>

              <div className="listing-keywords">
                <h2>Keywords <CopyToClipboard text={listingDetails.keywords} onCopy={handleCopy}>
                  <FaCopy className="copy-icon" />
                </CopyToClipboard></h2>
                <div className="keywords-box">
                  {listingDetails.keywords.split(',').map((keyword, index) => (
                    <span key={index} className="keyword-item">{keyword.trim()}</span>
                  ))}
                </div>
              </div>
            </div>

          <div className='buttons'>
            <button className="refresh-btn" onClick={handleRefresh}>
              <FaRedoAlt className="refresh-icon" /> <span className="refresh-text">Generate New Listing</span>
            </button>


            <button className="hire-expert-btn" onClick={handleClick}>
              <FontAwesomeIcon icon={faUserTie} className="hire-icon" /><span>Hire an Expert</span>
            </button>
            </div>    
         

            {/* <a onClick={handleClick}>
              <div className="hire-expert-cta">
                <div>
                <FontAwesomeIcon icon={faUserTie} className="hire-icon" />
                 </div>
                 <div className="hire-expert-text">
                  <h3>Hire an Expert</h3>
                  <p>Kickstart Your Amazon Business</p>
                 </div>
              </div>
            </a> */}
          </>
        )}

        {/* Unclosable Modal to collect user details */}
        <Modal          
        visible={modalVisible}
          footer={null} 
          closable={false}
        >
          <h1>Free Trial Over</h1>
          <p>Provide your details to get unlimited access to this tool</p>
          <Form layout="vertical" onValuesChange={(_, changedValues) => handleFormChange(changedValues)}>
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: 'Please enter your name' }]}
            >
              <Input placeholder="Enter your name" />
            </Form.Item>
            <Form.Item
              label="Phone Number"
              name="phone"
              rules={[{ required: true, message: 'Please enter a valid phone number' }]}
            >
              <Input placeholder="Enter your phone number" />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: 'Please enter a valid email address' }]}
            >
              <Input placeholder="Enter your email" />
            </Form.Item>

            <Button type="primary" onClick={handleModalSubmit} disabled={!formValid}>
              Submit
            </Button>
          </Form>
        </Modal>
      </header>
    </div>
  );
}

export default App;
